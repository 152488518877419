// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Get a job
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/`;
    return api.get(baseUrl, params.data);
}

/**
 * Get all jobs
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getJobs(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/`;
    return api.get(baseUrl, params.data);
}

/**
 * Create a new jobs
 * @param {*} params
 * @returns
 */
function createJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/create`;
    return api.create(baseUrl, params.data);
}

/**
 * Run jobs
 * @param {*} params
 * @returns
 */
function runJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/run`;
    return api.get(baseUrl, params.data);
    //return api.updatePatch(`${baseUrl}`, params);
}

/**
 * Start a job
 * @param {*} params
 * @returns
 */
function startJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/start`;
    return api.updatePatch(baseUrl, params.data);
    // return api.create(`${baseUrl}`, params.data);
}

/**
 * Start a job
 * @param {*} params
 * @returns
 */
function stopJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/stop`;
    return api.updatePatch(baseUrl, params.data);
    // return api.create(`${baseUrl}`, params.data);
}

/**
 * Update the details of a jobs
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/${params.id}`;
    return api.updatePatch(baseUrl, params.data);
}

// /**
//  * Get all jobs
//  * @param {*} data
//  * @param {*} params
//  * @returns
//  */
// function getJobById(params: any): any {
//     const baseUrl = `${config.server.api}/v1/jobs/${params.data.id}`;
//     return api.get(`${baseUrl}`);
// }

/**
 * Get jobs for a user
 * @param {*} params
 * @returns
 */
function deleteJob(params: any): any {
    const baseUrl = `${config.server.api}/v1/jobs/delete`;
    return api.updatePatch(baseUrl, params.data);
}

export { createJob, runJob, startJob, stopJob, deleteJob, updateJob, getJob, getJobs };
