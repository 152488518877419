// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new template
 * @param {*} params
 * @returns
 */
function createTemplate(params: any): any {
    const baseUrl = `${config.server.api}/v1/templates`;
    return api.create(`${baseUrl}`, params);
}

/**
 * Get a new template
 * @param {*} params
 * @returns
 */
function getTemplate(params: any): any {
    const id = params.id;
    const baseUrl = `${config.server.api}/v1/templates`;
    return api.get(`${baseUrl}`, id);
}

/**
 * Get a new template
 * @param {*} params
 * @returns
 */
function getUserTemplates(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.user}/templates`;
    return api.get(`${baseUrl}`);
}

/**
 * Get a new template
 * @param {*} params
 * @returns
 */
function updateTemplate(params: any): any {
    const id = params.id;
    const body = params;
    const baseUrl = `${config.server.api}/v1/templates`;
    return api.updatePatch(`${baseUrl}`, id, params);
}

/**
 * Get a new template
 * @param {*} params
 * @returns
 */
function deleteTemplate(params: any): any {
    const id = params.id;
    const isArchived = true;
    const baseUrl = `${config.server.api}/v1/templates`;
    return api.updatePatch(`${baseUrl}`, id);
}

export { createTemplate, getTemplate, updateTemplate, getUserTemplates, deleteTemplate };
