import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { templateApiResponseSuccess, templateApiResponseError } from './actions';
import { TemplateActionTypes } from './constants';
import config from '../../config';
import { toggleLoading } from '../actions';
import {
    createTemplate as createTemplateApi,
    getTemplate as getTemplateApi,
    getUserTemplates as getUserTemplatesApi,
    updateTemplate as updateTemplateApi,
} from '../../helpers';

// Import the API helpers
// This is needed for a work-around due to 401 errors
import { APICore } from '../../helpers/api/apiCore';

const api = new APICore();

/**
 * Create a new template
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.template
 * @param {object} action.payload.data
 */
function* createTemplate({ payload: { data } }) {
    // yield put(toggleLoading(true));
    try {
        const response = yield call(createTemplateApi, { data });
        yield put(templateApiResponseSuccess(TemplateActionTypes.CREATE_TEMPLATE, response.data));
    } catch (error) {
        console.log(error);
        yield put(templateApiResponseError(TemplateActionTypes.CREATE_TEMPLATE, error));
    }
    // yield put(toggleLoading(false)) is reloading the state which makes the call to
    // Templates/reducer without an action, by default will assign createSuccess to null.

    // yield put(toggleLoading(false));
}

/**
 * Get Templates
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.page
 * @param {string} action.payload.limit
 * @param {string} action.payload.sort
 * @param {string} action.payload.order
 * @param {string} action.payload.search
 * @param {string} action.payload.category
 */
// function* getTemplates({ payload = {} }) {
//     yield put(toggleLoading(true));
//     try {
//         payload = {
//             page: payload.page || 1,
//             limit: 12,
//             address_1: payload?.search?.address_1 ?? '',
//             zip: payload?.search?.zip ?? '',
//         };
//         let url = `${config.server.api}/v1/templates?`;
//         Object.keys(payload).forEach((k) => {
//             if (payload[k] !== '') {
//                 url += `${k}=${payload[k]}&`;
//             }
//         });
//         const res = yield api.get(url);
//         yield put(templateApiResponseSuccess(TemplateActionTypes.GET_TEMPLATES, res.data));
//     } catch (error) {
//         console.log(error);
//         yield put(templateApiResponseError(TemplateActionTypes.GET_TEMPLATES, error));
//     }
//     yield put(toggleLoading(false));
// }

/**
 * Get template by ID
 * @param {*} param0
 */
function* getTemplate({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTemplateApi, { id });
        yield put(templateApiResponseSuccess(TemplateActionTypes.GET_TEMPLATE_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(templateApiResponseError(TemplateActionTypes.GET_TEMPLATE_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get templates by user
 * @param {*} param0
 */
function* getUserTemplates({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getUserTemplatesApi, { user });
        yield put(templateApiResponseSuccess(TemplateActionTypes.GET_TEMPLATES_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(templateApiResponseError(TemplateActionTypes.GET_TEMPLATES_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a template
 */
function* updateTemplate({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(updateTemplateApi, { id, data });
        yield put(templateApiResponseSuccess(TemplateActionTypes.UPDATE_TEMPLATE, response.data));
    } catch (error) {
        console.log(error);
        yield put(templateApiResponseError(TemplateActionTypes.UPDATE_TEMPLATE, error));
    }
    yield put(toggleLoading(false));
}

// /**
//  * Get access by template
//  * @param {*} param0
//  * @param {*} param0.payload
//  * @param {*} param0.payload.id
//  * @param {*} param0.payload.data
//  */
// function* getAccessByTemplate({ payload: { id, data } }) {
//     yield put(toggleLoading(true));
//     try {
//         const response = yield call(getAccessByTemplateApi, { id, data });
//         yield put(templateApiResponseSuccess(TemplateActionTypes.GET_TEMPLATE_ACCESS, response.data));
//     } catch (error) {
//         console.log(error);
//         yield put(templateApiResponseError(TemplateActionTypes.GET_TEMPLATE_ACCESS, error));
//     }
//     yield put(toggleLoading(false));
// }

// /**
//  * Revoke access by template
//  * @param {*} param0
//  * @param {*} param0.payload
//  * @param {*} param0.payload.id
//  * @param {*} param0.payload.data
//  */
// function* revokeAccessByTemplate({ payload: { id, data } }) {
//     yield put(toggleLoading(true));
//     try {
//         const response = yield call(revokeAccessByTemplateApi, { id, data });
//         yield put(templateApiResponseSuccess(TemplateActionTypes.REVOKE_TEMPLATE_ACCESS, response.data));
//     } catch (error) {
//         console.log(error);
//         yield put(templateApiResponseError(TemplateActionTypes.REVOKE_TEMPLATE_ACCESS, error));
//     }
//     yield put(toggleLoading(false));
// }

export function* watchCreateTemplate() {
    yield takeEvery(TemplateActionTypes.CREATE_TEMPLATE, createTemplate);
}

// export function* watchGetTemplates() {
//     yield takeEvery(TemplateActionTypes.GET_TEMPLATES, getTemplates);
// }

export function* watchGetTemplate() {
    yield takeEvery(TemplateActionTypes.GET_TEMPLATE_BY_ID, getTemplate);
}

export function* watchGetUserTemplates() {
    yield takeEvery(TemplateActionTypes.GET_TEMPLATES_BY_USER, getUserTemplates);
}

export function* watchUpdateTemplate() {
    yield takeEvery(TemplateActionTypes.UPDATE_TEMPLATE, updateTemplate);
}

// export function* watchGetAccessByTemplate() {
//     yield takeEvery(TemplateActionTypes.GET_TEMPLATE_ACCESS, getAccessByTemplate);
// }

// export function* watchRevokeAccessByTemplate() {
//     yield takeEvery(TemplateActionTypes.REVOKE_TEMPLATE_ACCESS, revokeAccessByTemplate);
// }

function* templateSaga() {
    yield all([
        fork(watchCreateTemplate),
        // fork(watchGetTemplates),
        fork(watchGetTemplate),
        fork(watchGetUserTemplates),
        fork(watchUpdateTemplate),
        // fork(watchGetAccessByTemplate),
        // fork(watchRevokeAccessByTemplate),
    ]);
}

export default templateSaga;
