import { TimelinesActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
};

const Timeline = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TimelinesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TimelinesActionTypes.GET_TIMELINE_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case TimelinesActionTypes.CREATE_TIMELINE:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case TimelinesActionTypes.UPDATE_TIMELINE:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case TimelinesActionTypes.DELETE_TIMELINE:
                    return { ...state, data: action.payload.data, loading: false };
                case TimelinesActionTypes.ARCHIVE_TIMELINE:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case TimelinesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TimelinesActionTypes.GET_TIMELINE_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case TimelinesActionTypes.CREATE_TIMELINE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case TimelinesActionTypes.UPDATE_TIMELINE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case TimelinesActionTypes.DELETE_TIMELINE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case TimelinesActionTypes.ARCHIVE_TIMELINE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                default:
                    return { ...state };
            }
        // Get a single task
        case TimelinesActionTypes.GET_TIMELINE_BY_ID:
            return { ...state, data: null, loading: true };
        // Update a task
        case TimelinesActionTypes.UPDATE_TIMELINE:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case TimelinesActionTypes.RESET_TIMELINE:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

const Timelines = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TimelinesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TimelinesActionTypes.GET_TIMELINES:
                    return { ...state, data: action.payload.data, loading: false };
                case TimelinesActionTypes.GET_TIMELINES_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                case TimelinesActionTypes.GET_TIMELINES_BY_TRANSACTION:
                    return { ...state, data: action.payload.data, loading: false };
                case TimelinesActionTypes.GET_TIMELINES_BY_TRANSACTIONS:
                    return {
                        ...state,
                        data: [
                            ...(state.data || []).map((existingItem) => {
                                const newItem = action.payload.data.find((item) => item.id === existingItem.id);
                                return newItem ? { ...existingItem, ...newItem } : existingItem;
                            }),
                            ...action.payload.data.filter(
                                (item) => !(state.data || []).some((existingItem) => existingItem.id === item.id)
                            ),
                        ],
                        loading: false,
                    };
                default:
                    return { ...state };
            }
        case TimelinesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TimelinesActionTypes.GET_TIMELINES:
                    return { ...state, error: action.payload, loading: false };
                case TimelinesActionTypes.GET_TIMELINES_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case TimelinesActionTypes.GET_TIMELINES_BY_TRANSACTION:
                    return { ...state, error: action.payload, loading: false };
                case TimelinesActionTypes.GET_TIMELINES_BY_TRANSACTIONS:
                    return { ...state, error: action.payload, loading: false };
                default:
                    return { ...state };
            }
        // Get Tasks
        case TimelinesActionTypes.GET_TIMELINES:
            return { ...state, loading: true };
        case TimelinesActionTypes.GET_TIMELINES_BY_USER:
            return { ...state, data: null, loading: true };
        case TimelinesActionTypes.GET_TIMELINES_BY_TRANSACTION:
            return { ...state, data: null, loading: true };
        case TimelinesActionTypes.GET_TIMELINES_BY_TRANSACTIONS:
            return { ...state, data: null, loading: true };
        case TimelinesActionTypes.RESET_TIMELINES:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export { Timeline, Timelines };
