export const TaskTemplateActionTypes = {
    API_RESPONSE_SUCCESS: '@@task_templates/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@task_templates/API_RESPONSE_ERROR',

    GET_TEMPLATES: '@@task_templates/GET_TEMPLATES',
    GET_TEMPLATE_BY_ID: '@@task_templates/GET_TEMPLATE_BY_ID',
    GET_TEMPLATES_BY_USER: '@@task_templatess/GET_TEMPLATES_BY_USER',
    GET_TEMPLATE_ACCESS: '@@task_templates/GET_TEMPLATE_ACCESS',
    REVOKE_TEMPLATE_ACCESS: '@@task_templates/REVOKE_TEMPLATE_ACCESS',
    CREATE_TEMPLATE: '@@task_templates/CREATE_TEMPLATE',
    UPDATE_TEMPLATE: '@@task_templates/UPDATE_TEMPLATE',
    DELETE_TEMPLATE: '@@task_templates/DELETE_TEMPLATE',
    RESET_TEMPLATES: '@@task_templates/RESET_TEMPLATES',
};
