// @flow
import { combineReducers } from 'redux';

import Addendum from './addendum/reducers';
import Address from './address/reducers';
import Anvil from './anvil/reducers';
import NewAnvil from './new_anvil/reducers';
import Auth from './auth/reducers';
import Comments from './comments/reducers';
import Layout from './layout/reducers';
import Transactions from './transactions/reducers';
import { Listing, ListingAccess } from './listings/reducers';
import PropertyTypes from './property_types/reducers';
import PropertyStyles from './property_styles/reducers';
import { Offer, OfferAccess } from './offer/reducers';
import Disclosures from './disclosures/reducers';
import User from './users/reducers';
import Templates from './templates/reducers';
import TaskTemplates from './task_templates/reducers';
import { Task, Tasks } from './tasks/reducers';
import { Timeline, Timelines } from './timelines/reducers';
import Files from './files/reducers';
import Messages from './messages/reducers';
import Repairs from './repairs/reducers';
import Extension from './extension/reducers';
import Rentback from './rentback/reducers';
import Termination from './termination/reducers';
import { Notification, Notifications } from './notifications/reducers';
import { Organization, Organizations, OrganizationSettings } from './organizations/reducers';
import { Invitation, Invitations } from './invitations/reducers';
import { Favorite, Favorites } from './favorites/reducers';
import Billing from './billing/reducers';
import Modal from './modals/reducers';

export default (combineReducers({
    Addendum,
    Address,
    Anvil,
    NewAnvil,
    Auth,
    Comments,
    Disclosures,
    Layout,
    Listing,
    ListingAccess,
    PropertyTypes,
    PropertyStyles,
    Offer,
    OfferAccess,
    Task,
    Tasks,
    Templates,
    TaskTemplates,
    Timeline,
    Timelines,
    Transactions,
    User,
    Files,
    Messages,
    Repairs,
    Extension,
    Rentback,
    Termination,
    Notification,
    Notifications,
    Organization,
    Organizations,
    OrganizationSettings,
    Invitation,
    Invitations,
    Favorite,
    Favorites,
    Billing,
    Modal,
}): any);
