import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { taskTemplateApiResponseSuccess, taskTemplateApiResponseError } from './actions';
import { TaskTemplateActionTypes } from './constants';
import config from '../../config';
import { toggleLoading } from '../actions';
import {
    createTaskTemplate as createTaskTemplateApi,
    getTaskTemplate as getTaskTemplateApi,
    getUserTaskTemplates as getUserTaskTemplatesApi,
    updateTaskTemplate as updateTaskTemplateApi,
} from '../../helpers';

// Import the API helpers
// This is needed for a work-around due to 401 errors
import { APICore } from '../../helpers/api/apiCore';

const api = new APICore();

/**
 * Create a new taskTemplate
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.taskTemplate
 * @param {object} action.payload.data
 */
function* createTaskTemplate({ payload: { data } }) {
    console.log('DATA AT SAGA: ', data);

    // yield put(toggleLoading(true));
    try {
        const response = yield call(createTaskTemplateApi, { data });
        yield put(taskTemplateApiResponseSuccess(TaskTemplateActionTypes.CREATE_TEMPLATE, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskTemplateApiResponseError(TaskTemplateActionTypes.CREATE_TEMPLATE, error));
    }
    // yield put(toggleLoading(false)) is reloading the state which makes the call to
    // TaskTemplates/reducer without an action, by default will assign createSuccess to null.

    // yield put(toggleLoading(false));
}

/**
 * Get TaskTemplates
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.page
 * @param {string} action.payload.limit
 * @param {string} action.payload.sort
 * @param {string} action.payload.order
 * @param {string} action.payload.search
 * @param {string} action.payload.category
 */
// function* getTaskTemplates({ payload = {} }) {
//     yield put(toggleLoading(true));
//     try {
//         payload = {
//             page: payload.page || 1,
//             limit: 12,
//             address_1: payload?.search?.address_1 ?? '',
//             zip: payload?.search?.zip ?? '',
//         };
//         let url = `${config.server.api}/v1/taskTemplates?`;
//         Object.keys(payload).forEach((k) => {
//             if (payload[k] !== '') {
//                 url += `${k}=${payload[k]}&`;
//             }
//         });
//         const res = yield api.get(url);
//         yield put(taskTemplateApiResponseSuccess(TaskTemplateActionTypes.GET_TEMPLATES, res.data));
//     } catch (error) {
//         console.log(error);
//         yield put(taskTemplateApiResponseError(TaskTemplateActionTypes.GET_TEMPLATES, error));
//     }
//     yield put(toggleLoading(false));
// }

/**
 * Get taskTemplate by ID
 * @param {*} param0
 */
function* getTaskTemplate({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTaskTemplateApi, { id });
        yield put(taskTemplateApiResponseSuccess(TaskTemplateActionTypes.GET_TEMPLATE_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskTemplateApiResponseError(TaskTemplateActionTypes.GET_TEMPLATE_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get taskTemplates by user
 * @param {*} param0
 */
function* getUserTaskTemplates({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getUserTaskTemplatesApi, { user });
        yield put(taskTemplateApiResponseSuccess(TaskTemplateActionTypes.GET_TEMPLATES_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskTemplateApiResponseError(TaskTemplateActionTypes.GET_TEMPLATES_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a taskTemplate
 */
function* updateTaskTemplate({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(updateTaskTemplateApi, { id, data });
        yield put(taskTemplateApiResponseSuccess(TaskTemplateActionTypes.UPDATE_TEMPLATE, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskTemplateApiResponseError(TaskTemplateActionTypes.UPDATE_TEMPLATE, error));
    }
    yield put(toggleLoading(false));
}

// /**
//  * Get access by taskTemplate
//  * @param {*} param0
//  * @param {*} param0.payload
//  * @param {*} param0.payload.id
//  * @param {*} param0.payload.data
//  */
// function* getAccessByTaskTemplate({ payload: { id, data } }) {
//     yield put(toggleLoading(true));
//     try {
//         const response = yield call(getAccessByTaskTemplateApi, { id, data });
//         yield put(taskTemplateApiResponseSuccess(TaskTemplateActionTypes.GET_TEMPLATE_ACCESS, response.data));
//     } catch (error) {
//         console.log(error);
//         yield put(taskTemplateApiResponseError(TaskTemplateActionTypes.GET_TEMPLATE_ACCESS, error));
//     }
//     yield put(toggleLoading(false));
// }

// /**
//  * Revoke access by taskTemplate
//  * @param {*} param0
//  * @param {*} param0.payload
//  * @param {*} param0.payload.id
//  * @param {*} param0.payload.data
//  */
// function* revokeAccessByTaskTemplate({ payload: { id, data } }) {
//     yield put(toggleLoading(true));
//     try {
//         const response = yield call(revokeAccessByTaskTemplateApi, { id, data });
//         yield put(taskTemplateApiResponseSuccess(TaskTemplateActionTypes.REVOKE_TEMPLATE_ACCESS, response.data));
//     } catch (error) {
//         console.log(error);
//         yield put(taskTemplateApiResponseError(TaskTemplateActionTypes.REVOKE_TEMPLATE_ACCESS, error));
//     }
//     yield put(toggleLoading(false));
// }

export function* watchCreateTaskTemplate() {
    yield takeEvery(TaskTemplateActionTypes.CREATE_TEMPLATE, createTaskTemplate);
}

// export function* watchGetTaskTemplates() {
//     yield takeEvery(TaskTemplateActionTypes.GET_TEMPLATES, getTaskTemplates);
// }

export function* watchGetTaskTemplate() {
    yield takeEvery(TaskTemplateActionTypes.GET_TEMPLATE_BY_ID, getTaskTemplate);
}

export function* watchGetUserTaskTemplates() {
    yield takeEvery(TaskTemplateActionTypes.GET_TEMPLATES_BY_USER, getUserTaskTemplates);
}

export function* watchUpdateTaskTemplate() {
    yield takeEvery(TaskTemplateActionTypes.UPDATE_TEMPLATE, updateTaskTemplate);
}

// export function* watchGetAccessByTaskTemplate() {
//     yield takeEvery(TaskTemplateActionTypes.GET_TEMPLATE_ACCESS, getAccessByTaskTemplate);
// }

// export function* watchRevokeAccessByTaskTemplate() {
//     yield takeEvery(TaskTemplateActionTypes.REVOKE_TEMPLATE_ACCESS, revokeAccessByTaskTemplate);
// }

function* taskTemplateSaga() {
    yield all([
        fork(watchCreateTaskTemplate),
        // fork(watchGetTaskTemplates),
        fork(watchGetTaskTemplate),
        fork(watchGetUserTaskTemplates),
        fork(watchUpdateTaskTemplate),
        // fork(watchGetAccessByTaskTemplate),
        // fork(watchRevokeAccessByTaskTemplate),
    ]);
}

export default taskTemplateSaga;
