// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new Task
 * @param {*} params
 * @returns
 */
function createTask(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${id}/tasks`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Update the details of a Task
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateTask(params: any): any {
    const baseUrl = `${config.server.api}/v1/tasks/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get a single task
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getTask(params: any): any {
    const baseUrl = `${config.server.api}/v1/tasks/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get tasks
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getTasks(params: any): any {
    const baseUrl = `${config.server.api}/v1/tasks`;
    return api.get(`${baseUrl}`);
}

/**
 * Get tasks for a user
 * @param {*} params
 * @returns
 */
function getUserTasks(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.user}/tasks`;
    return api.get(`${baseUrl}`);
}

/**
 * Get tasks for a transaction
 * @param {*} params
 * @returns
 */
function getTasksByTransaction(params: any): any {
    const baseUrl = `${config.server.api}/v1/transactions/${params.id}/tasks`;
    return api.get(`${baseUrl}`);
}

/**
 * Get tasks for a transactions
 * @param {*} params
 * @returns
 */
function getTasksByTransactions(params: any): any {
    const baseUrl = `${config.server.api}/v1/transactions/tasks?ids=${params.ids}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get tasks for a transaction
 * @param {*} params
 * @returns
 */
function getTasksByListing(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings/${params.id}/tasks`;
    return api.get(`${baseUrl}`);
}

/**
 * Create a new comment on a task
 * @param {*} params
 * @returns
 */
function createTaskComment(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/tasks/${id}/comments`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Create a new comment on a task
 * @param {*} params
 * @returns
 */
function createTaskAttachment(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/tasks/${id}/attachments`;
    return api.create(`${baseUrl}`, data);
}

export {
    createTask,
    createTaskComment,
    createTaskAttachment,
    updateTask,
    getTask,
    getTasks,
    getUserTasks,
    getTasksByTransaction,
    getTasksByTransactions,
    getTasksByListing,
};
