export const TimelinesActionTypes = {
    API_RESPONSE_SUCCESS: '@@timelines/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@timelines/API_RESPONSE_ERROR',
    // Timelines
    GET_TIMELINES: '@@timelines/GET_TIMELINES',
    GET_TIMELINE_BY_ID: '@@timelines/GET_TIMELINE_BY_ID',
    GET_TIMELINES_BY_USER: '@@timelines/GET_TIMELINES_BY_USER',
    GET_TIMELINES_BY_TRANSACTION: '@@timelines/GET_TIMELINES_BY_TRANSACTION',
    GET_TIMELINES_BY_TRANSACTIONS: '@@timelines/GET_TIMELINES_BY_TRANSACTIONS',
    CREATE_TIMELINE: '@@timelines/CREATE_TIMELINE',
    UPDATE_TIMELINE: '@@timelines/UPDATE_TIMELINE',
    DELETE_TIMELINE: '@@timelines/DELETE_TIMELINE',
    ARCHIVE_TIMELINE: '@@timelines/ARCHIVE_TIMELINE',
    RESET_TIMELINES: '@@timelines/RESET_TIMELINES',
    RESET_TIMELINE: '@@timelines/RESET_TIMELINE',
};
