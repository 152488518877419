import { TaskTemplateActionTypes } from './constants';

type TaskTemplateAction = { type: string; payload: {} | string };

// common success
export const taskTemplateApiResponseSuccess = (actionType: string, data: any): TaskTemplateAction => ({
    type: TaskTemplateActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const taskTemplateApiResponseError = (actionType: string, error: string): TaskTemplateAction => ({
    type: TaskTemplateActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createTaskTemplate = (data: object): TaskTemplateAction => ({
    type: TaskTemplateActionTypes.CREATE_TEMPLATE,
    payload: { data },
});

export const getTaskTemplates = (options: object): TaskTemplateAction => ({
    type: TaskTemplateActionTypes.GET_TEMPLATES,
    payload: options,
});

export const getTaskTemplate = (id: string): TaskTemplateAction => ({
    type: TaskTemplateActionTypes.GET_TEMPLATE_BY_ID,
    payload: { id },
});

export const getTaskTemplatesByUser = (user: string): TaskTemplateAction => ({
    type: TaskTemplateActionTypes.GET_TEMPLATES_BY_USER,
    payload: { user },
});

export const updateTaskTemplate = (id: string, data: object): TaskTemplateAction => ({
    type: TaskTemplateActionTypes.UPDATE_TEMPLATE,
    payload: { id, data },
});

export const getAccessByTaskTemplate = (id: string): TaskTemplateAction => ({
    type: TaskTemplateActionTypes.GET_TEMPLATE_ACCESS,
    payload: { id },
});

export const revokeAccessByTaskTemplate = (id: string, data: object): TaskTemplateAction => ({
    type: TaskTemplateActionTypes.REVOKE_TEMPLATE_ACCESS,
    payload: { id, data },
});

export const resetTaskTemplates = (): TaskTemplateAction => ({
    type: TaskTemplateActionTypes.RESET_TEMPLATES,
    payload: {},
});
