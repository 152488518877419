import { TasksActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    createSuccess: null,
    updateSuccess: null,
};

const Task = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TasksActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TasksActionTypes.GET_TASK_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case TasksActionTypes.CREATE_TASK:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        createSuccess: true,
                    };
                case TasksActionTypes.UPDATE_TASK:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case TasksActionTypes.DELETE_TASK:
                    return { ...state, data: action.payload.data, loading: false };
                case TasksActionTypes.ARCHIVE_TASK:
                    return { ...state, data: action.payload.data, loading: false };
                case TasksActionTypes.CREATE_COMMENT:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case TasksActionTypes.CREATE_ATTACHMENT:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case TasksActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TasksActionTypes.GET_TASK_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case TasksActionTypes.CREATE_TASK:
                    return { ...state, error: action.payload, loading: false, createSuccess: false };
                case TasksActionTypes.UPDATE_TASK:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case TasksActionTypes.DELETE_TASK:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case TasksActionTypes.ARCHIVE_TASK:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case TasksActionTypes.CREATE_COMMENT:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case TasksActionTypes.CREATE_ATTACHMENT:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                default:
                    return { ...state };
            }
        // Get a single task
        case TasksActionTypes.GET_TASK_BY_ID:
            return { ...state, data: null, loading: true };
        // Update a task
        case TasksActionTypes.UPDATE_TASK:
            return { ...state, id: action.payload.id, data: action.payload.data, updateSuccess: false, loading: true };
        // Create a task
        case TasksActionTypes.CREATE_TASK:
            return { ...state, data: null, createSuccess: false, loading: true };
        case TasksActionTypes.RESET_TASK:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

const Tasks = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TasksActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TasksActionTypes.GET_TASKS:
                    return { ...state, data: action.payload.data, loading: false };
                case TasksActionTypes.GET_TASKS_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                case TasksActionTypes.GET_TASKS_BY_TRANSACTION:
                    return { ...state, data: action.payload.data, loading: false };
                case TasksActionTypes.GET_TASKS_BY_TRANSACTIONS:
                    return {
                        ...state,
                        data: [
                            ...(state.data || []).map((existingItem) => {
                                const newItem = action.payload.data.find((item) => item.id === existingItem.id);
                                return newItem ? { ...existingItem, ...newItem } : existingItem;
                            }),
                            ...action.payload.data.filter(
                                (item) => !(state.data || []).some((existingItem) => existingItem.id === item.id)
                            ),
                        ],
                        loading: false,
                    };
                case TasksActionTypes.GET_TASKS_BY_LISTING:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case TasksActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TasksActionTypes.GET_TASKS:
                    return { ...state, error: action.payload, loading: false };
                case TasksActionTypes.GET_TASKS_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case TasksActionTypes.GET_TASKS_BY_TRANSACTION:
                    return { ...state, error: action.payload, loading: false };
                case TasksActionTypes.GET_TASKS_BY_TRANSACTIONS:
                    return { ...state, error: action.payload, loading: false };
                case TasksActionTypes.GET_TASKS_BY_LISTING:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        // Get Tasks
        case TasksActionTypes.GET_TASKS:
            return { ...state, loading: true };
        case TasksActionTypes.GET_TASKS_BY_USER:
            return { ...state, data: null, loading: true };
        case TasksActionTypes.GET_TASKS_BY_TRANSACTION:
            return { ...state, loading: true };
        case TasksActionTypes.GET_TASKS_BY_TRANSACTIONS:
            return { ...state, loading: true };
        case TasksActionTypes.GET_TASKS_BY_LISTING:
            return { ...state, loading: true };
        case TasksActionTypes.RESET_TASKS:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export { Task, Tasks };
